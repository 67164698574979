#logout-button {
    background-color: red;
    color: white;
    border: none;
    padding: 5px 10px;
}

#logout-button[disabled] {
    background-color: lightgray;
    color: gray;
}

/* Footer.css */
footer {
    background-color: rgb(29, 7, 7);
    color: white;
    padding: 20px;
    text-align: center;
    position: fixed;
    bottom: 0;
    width: 100%;
}

.navbar {
    /*background-color: rgb(11, 23, 68);*/
    color: rgba(5, 5, 82, 0.81);
    padding: 10px;
    text-align: center;
    position: fixed;
    top: 10px;
    width: 100%;
}